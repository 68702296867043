<template>
  <v-container id="support" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="dataFiltered"
      :search="search"
      sort-by="date"
      :sort-desc="true"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-btn color="success" dark class="mb-2" @click="openDialog()">
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar flat class="toolbar-adjust">
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="statusFilter"
                :label="str['status']"
                item-text="label"
                item-value="value"
                :items="issueStatusList"
                :no-data-text="str['no_data']"
                clearable
                hide-details
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="typeFilter"
                :label="str['type']"
                item-text="label"
                item-value="value"
                :items="issueTypesList"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.type_str="{ item }">
        <span
          v-if="issueTypesDict[item.type]"
          :class="[issueTypesDict[item.type] && issueTypesDict[item.type].type ? issueTypesDict[item.type].type + '--text' : '']"
        >
          {{ issueTypesDict[item.type].label }}
        </span>
      </template>
      <template #item.user_id="{ item }">
        <div :class="{ 'link-text': item.user_id }" @click.stop="openClient(item.user_id)">
          {{ item.user_id }}
        </div>
      </template>
      <template #item.status_str="{ item }">
        <span
          v-if="issueStatusDict[item.status]"
          :class="[issueStatusDict[item.status] && issueStatusDict[item.status].type ? issueStatusDict[item.status].type + '--text' : '']"
        >
          {{ issueStatusDict[item.status].label }}
        </span>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialogItem" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemToEdit ? str['edit_issue'] : str['new_issue'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="item in fields" :key="item.id" :cols="item.cols ? item.cols : 12">
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                  :disabled="item.disabled"
                  :search-input.sync="item.searchInput"
                  @change="item.searchInput = ''"
                />
                <div v-if="item.type === 'image'">
                  <div>
                    {{ str['image'] + (item.required ? ' *' : '') }}
                  </div>
                  <div v-if="item.value" class="form-field-image">
                    <input id="input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                    <v-img contain :src="item.value" width="300px" height="300px" />
                  </div>
                  <div v-if="!item.value" class="form-field-image">
                    <input id="input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                    <v-btn class="mx-2" fab dark small color="success">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeDialog()">
            {{ str['cancel'] }}
          </v-btn>
          <!-- <v-btn
            v-if="itemToEdit"
            color="error"
            @click="deleteItem()"
          >
            {{ str['delete'] }}
          </v-btn> -->
          <v-btn color="success" :disabled="!fieldsValidated()" @click="saveItem()">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    return {
      str: window.strings,
      user: Utils.getUser(),
      issueTypesList: this.getIssueTypesList(),
      issueTypesDict: this.getIssueTypesDict(),
      issueStatusList: this.getIssueStatusList(),
      issueStatusDict: this.getIssueStatusDict(),
      data: [],
      search: '',
      headers: [
        { text: window.strings['id'], value: 'id', align: 'left', width: 60 },
        {
          text: window.strings['date'],
          value: 'date',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['status'],
          value: 'status_str',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['title'],
          value: 'title',
          align: 'center',
          width: 250,
        },
        {
          text: window.strings['type'],
          value: 'type_str',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['client_id'],
          value: 'user_id',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['email'],
          value: 'email',
          align: 'center',
          width: 240,
        },
        {
          text: window.strings['mobile_phone'],
          value: 'phone',
          align: 'center',
          width: 170,
        },
      ],
      fields: [
        {
          type: 'select',
          id: 'type',
          title: window.strings['type'],
          items: Utils.getReportIssueTypes(),
          value: '',
          searchInput: '',
          required: true,
          cols: 6,
        },
        {
          type: 'select',
          id: 'status',
          title: window.strings['status'],
          items: Utils.getReportIssueStatus(),
          value: '',
          searchInput: '',
          required: true,
          cols: 6,
        },
        {
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          value: '',
          required: true,
        },
        {
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
          required: true,
        },
        {
          type: 'input',
          id: 'user_id',
          title: window.strings['client_id'],
          value: '',
          number: true,
          required: false,
          cols: 4,
        },
        {
          type: 'input',
          id: 'email',
          title: window.strings['email'],
          value: '',
          required: false,
          cols: 8,
        },
        {
          type: 'input',
          id: 'phone',
          title: window.strings['mobile_phone'],
          value: '',
          required: false,
          cols: 4,
        },
        {
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          required: false,
          cols: 8,
        },
        {
          type: 'input',
          id: 'device',
          title: window.strings['device'],
          value: '',
          required: false,
        },
        {
          type: 'input',
          id: 'attachment',
          title: window.strings['attachment'] + ' (' + window.strings['url'] + ')',
          value: '',
          required: false,
        },
        {
          type: 'image',
          id: 'images',
          title: window.strings['images'],
          value: '',
          required: false,
        },
      ],
      dialogItem: false,
      itemToEdit: null,
      imageMaxSize: 300,
      statusFilter: '',
      typeFilter: '',
    }
  },
  computed: {
    dataFiltered() {
      const self = this
      let data = this.data
      if (this.statusFilter !== null && this.statusFilter !== '') {
        data = data.filter(function (item) {
          return item.status === self.statusFilter
        })
      }
      if (this.typeFilter !== null && this.typeFilter !== '') {
        data = data.filter(function (item) {
          return item.type === self.typeFilter
        })
      }
      return data
    },
  },
  watch: {
    dialogItem(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeDialog()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getIssues()
  },
  methods: {
    getIssueStatusList: function () {
      const items = JSON.parse(JSON.stringify(Utils.getReportIssueStatus()))
      items.unshift({
        label: window.strings['all'],
        value: '',
      })
      return items
    },
    getIssueStatusDict: function () {
      const items = Utils.getReportIssueStatus()
      const dict = {}
      if (items && items.length) {
        items.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getIssueTypesList: function () {
      const items = JSON.parse(JSON.stringify(Utils.getReportIssueTypes()))
      items.unshift({
        label: window.strings['all'],
        value: '',
      })
      return items
    },
    getIssueTypesDict: function () {
      const items = Utils.getReportIssueTypes()
      const dict = {}
      if (items && items.length) {
        items.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getIssues: function () {
      const self = this
      this.$isLoading(true)
      Api.getReportIssues(function (response) {
        self.$isLoading(false)
        if (response.success) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].status_str = self.issueStatusDict[response.data[i].status]
              ? self.issueStatusDict[response.data[i].status].label
              : response.data[i].status
            response.data[i].type_str = self.issueTypesDict[response.data[i].type]
              ? self.issueTypesDict[response.data[i].type].label
              : response.data[i].type
          }
          self.data = response.data
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    openDialog(item) {
      this.itemToEdit = item
      this.setData()
      this.dialogItem = true
    },
    closeDialog() {
      this.dialogItem = false
      this.itemToEdit = null
    },
    fieldsValidated() {
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
          return false
        }
      }
      return true
    },
    addNewImage(event) {
      const self = this
      const file = event.srcElement.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        const image = new Image()
        image.src = reader.result
        image.onload = function () {
          let out = null
          if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
            out = e.target.result
          } else {
            out = resizeImage(image)
          }
          self.saveImage(out)
        }
      }
      reader.onerror = function () {}

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }
    },
    saveImage(image) {
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id === 'images') {
          this.fields[i].value = image
          this.$set(this.fields, i, this.fields[i])
          break
        }
      }
      document.getElementById('input-image').value = ''
    },
    saveItem() {
      const self = this
      const data = this.getData()
      if (data) {
        this.$isLoading(true)
        if (this.itemToEdit) {
          data.id = this.itemToEdit.id
          Api.updateReportIssue(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeDialog()
              self.getIssues()
            } else {
              self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newReportIssue(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeDialog()
              self.getIssues()
            } else {
              self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    getData() {
      const data = {}
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
          return false
        }
        if (this.fields[i].id === 'images') {
          data[this.fields[i].id] = this.fields[i].value ? JSON.stringify([this.fields[i].value]) : ''
        } else {
          if (this.fields[i].number) {
            data[this.fields[i].id] = parseInt(this.fields[i].value)
          } else {
            data[this.fields[i].id] = this.fields[i].value
          }
        }
      }
      return data
    },
    setData() {
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id === 'images') {
          this.fields[i].value = this.itemToEdit && this.itemToEdit.images ? JSON.parse(this.itemToEdit.images)[0] : null
        } else {
          this.fields[i].value =
            this.itemToEdit && (this.itemToEdit[this.fields[i].id] || this.itemToEdit[this.fields[i].id] === 0)
              ? this.itemToEdit[this.fields[i].id]
              : ''
        }
      }
    },
    deleteItem() {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deleteReportIssue(
            {
              id: this.itemToEdit.id,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.getIssues()
              } else {
                self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    openClient: function (id) {
      const self = this
      if (id) {
        this.$isLoading(true)
        Api.getUserWithId(
          {
            id: id,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              const client = Utils.encodeClient(response.data[0])
              Utils.setStorage('client', client)
              window.clientPageCacheList = null
              self.$router.navigate.push({
                path: '/home/client',
                query: { tab: 'details' },
              })
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
  },
}
</script>
